.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #262626;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.items {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    margin: 2rem;
}

.itemImg {
    max-width: 100%;
    max-height: 100%;
    border-radius: 1em;
    transition: all 0.3s ease-in-out;
}

.itemLink {
    color: #9c3eba;
    transition: color 0.3s ease-in-out;
    text-decoration: none;
}

.itemLink:hover {
    color: #633194;
}

.itemImg:hover {
    transform: scale(1.05);
}
